export enum InstalmentIntervalEnum {
  Monthly = 1,
  Bimonthly = 2,
  Quarterly = 3,
  Biannual = 4
}

export enum InterestRateIntervalEnum {
  Yearly = 1,
  PerInterval = 2,
  PerEntireDuration = 3
}
