import { InstalmentIntervalEnum } from './enums'
import { InstalmentModel } from './instalment-model.type'

export class YearMonthDay {
  year: number = 2020
  month: number = 1
  day: number = 1
}

export class DdrModel {
  customerId: string = ''
  policyNumber: string = ''
  policyTotal: number = 0
  paymentPlanRevisionId: number = 0
  instalmentIntervalId: InstalmentIntervalEnum = InstalmentIntervalEnum.Monthly
  depositAmount: number = 0
  depositDate: Date | null = new Date()
  continuousInstalmentAmount: number = 0
  firstPaymentDate: Date | null = new Date()
  regularPaymentsDate: YearMonthDay = new YearMonthDay()
  transactionReference: string = ''
  yourReference: string = ''
  processorReference: string = ''
  instalments: Array<InstalmentModel> = []
}
