







































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AxiosResponse, AxiosError } from 'axios'
import { LunaModel } from '../models/luna/luna-model.type'
import { FormDataModel } from '../models/shared/form-data-model.type'
import { QuoteModel } from '../models/luna/quote-model.type'
import { Validators } from '../components/validation/validators'
import { ResultResponseModel } from '../models/shared/result-response-model.type'
import { LabelValueModel } from '../models/shared/label-value-model.type'
import { VerificationResultModel } from '../models/luna/verification-result-model.type'

@Component({})
export default class Luna extends Vue {
  // control flags
  hasAccount = null;
  hasAccountAuthorityForDebits = null

  agreedToTerms: boolean = false
  agreedToAffordabilityCheck: boolean = false
  agreedToMonthlyPayments: boolean = false

  readyForApplication: boolean = false

  agreementDownloadInProgress: boolean = false
  creditCheckInProgress: boolean = false
  loanCreationInProgress: boolean = false
  gettingQuoteInProgress: boolean = true
  isDone: boolean = false
  isValid: boolean = false
  // end control flags

  // modal control flags
  showAccountDetailsConfirmationModal = false
  showCreditCheckFailedModal = false
  accountDetailsConfirmed = false
  showErrorModal = false
  // end modal control flags

  validators = new Validators()
  customerName: string = ''
  c2msCustomerId: string = ''
  lunaCustomerId: string = ''
  policyNumber: string = ''
  productId: string = ''
  quote: QuoteModel | null = null
  editedItem: LunaModel = new LunaModel()
  sessionId: string = ''

  // add the rest
  loanStartDate: string = new Date().toISOString().substr(0, 10)
  requestedPaymentDays: Array<LabelValueModel> = []
  errors: Array<string> = []
  returnUrl: string = ''
  externalStylesheet: string = '';

  private created () {
    for (var i = 1; i <= 28; i++) {
      this.requestedPaymentDays.push(new LabelValueModel(i.toString(), i))
    }

    if (!this.$route.params['sessionId']) {
      return
    }

    this.sessionId = this.$route.params['sessionId']

    // validate session
    this.axios.post<FormDataModel>('api/forms/validatesession/' + this.sessionId, null).then((response: AxiosResponse<FormDataModel>) => {
      this.customerName = response.data.customerName
      this.returnUrl = response.data.returnUrl

      let inceptionDate = new Date(String(response.data.inceptionDate).substr(0, 10))

      this.loanStartDate = inceptionDate.toISOString().substr(0, 10)
      this.editedItem.loanStartDate = new Date(this.loanStartDate)
      // set regular payments day to original inceptionDate's day
      this.editedItem.requestedPaymentDay = inceptionDate.getDate()
      this.c2msCustomerId = response.data.customer.id
      this.lunaCustomerId = response.data.customer.externalReference
      this.policyNumber = response.data.policyId

      this.productId = response.data.productId

      this.externalStylesheet = response.data.stylesheetCDN

      this.errors = []

      this.gettingQuoteInProgress = true

      // get quote
      this.axios.post<ResultResponseModel<QuoteModel>>('api/luna/getquote/' + this.sessionId, {}).then((response: AxiosResponse<ResultResponseModel<QuoteModel>>) => {
        this.quote = this.handleResponse(response)
        this.gettingQuoteInProgress = false
      }).catch((error: AxiosError) => {
        this.errors.push('Could not get quote. Please try again. Payment can not continue without quote')
        this.errors.push(error.response!.data)
        this.showErrorModal = true
        this.gettingQuoteInProgress = false
        return Promise.reject(error)
      })
    }).catch((error: AxiosError) => {
      this.errors.push('Could not validate session. Session expired or does not exist. Can not continue.')
      this.showErrorModal = true
      this.gettingQuoteInProgress = false
      return Promise.reject(error)
    })
  }

  isAccountOwnerAndAuthorisedForDebits () {
    return this.hasAccount === 'true' && this.hasAccountAuthorityForDebits === 'true'
  }

  confirmAgreements () {
    // first step action
    this.showAccountDetailsConfirmationModal = true
  }

  confirmAccountDetails () {
    // second step action

    // perform credit check
    if (this.quote && this.quote.affordabilityCheckRequired) {
      this.creditCheckInProgress = true

      this.axios.post<ResultResponseModel<VerificationResultModel>>('api/luna/checkaffordability/' + this.sessionId, this.editedItem).then((response: AxiosResponse<ResultResponseModel<VerificationResultModel>>) => {
        this.handleResponse(response)

        // credit check failed
        if (response.data.result && !response.data.result.success) {
          this.showAccountDetailsConfirmationModal = false
          this.showCreditCheckFailedModal = true
          this.readyForApplication = false
          this.isDone = true
        } else {
          // credit check was successful
          this.setReadyForApplication()
        }
      }).catch((error: AxiosError) => {
        this.creditCheckInProgress = false
        this.errors.push(error.response!.data)
        this.showErrorModal = true
        return Promise.reject(error)
      }).then(() => {
        this.creditCheckInProgress = false
      })
    } else {
      this.setReadyForApplication()
    }
  }

  setReadyForApplication () {
    this.showAccountDetailsConfirmationModal = false
    this.readyForApplication = true
  }

  handleResponse<T> (response: AxiosResponse<ResultResponseModel<T>>, clearErrors: boolean = true) : T | null {
    if (clearErrors) {
      this.errors = []
    }
    if (response.data.isValid) {
      return response.data.result
    } else {
      this.errors.push(...response.data.errors)
      this.showErrorModal = true
    }

    return response.data.result
  }

  formatDate (date: string) : string {
    // todo: should be configurable
    let d = new Date(date)

    return d.toLocaleDateString('en')
  }

  formatAmount (amount: number) : string {
    return amount.toFixed(2)
  }

  downloadAgreement () {
    this.agreementDownloadInProgress = true

    this.axios.post<ResultResponseModel<any>>('api/luna/downloadagreement/' + this.sessionId, this.editedItem).then((response: AxiosResponse<ResultResponseModel<any>>) => {
      const result = this.handleResponse(response)

      var binaryString = window.atob(result)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }

      var blob = new Blob([bytes], { type: 'application/pdf' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var fileName = 'agreement.pdf'
      link.download = fileName
      link.click()
      link.remove()
    }).catch((error: AxiosError) => {
      this.agreementDownloadInProgress = false
      this.errors.push(error.response!.data)
      this.showErrorModal = true
      return Promise.reject(error)
    }).then(() => {
      this.agreementDownloadInProgress = false
    })
  }

  apply () {
    this.loanCreationInProgress = true
    this.editedItem.agreedToTerms = this.agreedToTerms && this.agreedToMonthlyPayments

    this.axios.post<ResultResponseModel<string>>('api/luna/acceptquote/' + this.sessionId, this.editedItem).then((response: AxiosResponse<ResultResponseModel<string>>) => {
      this.errors = []

      if (response.data.isValid) {
        this.isDone = true
        this.loanCreationInProgress = false

        if (parent) {
          parent.postMessage({ type: 'payment', success: true }, '*')
        }

        // redirect
        window.location.href = this.returnUrl
      } else {
        this.loanCreationInProgress = false
        this.errors.push(...response.data.errors)
        this.showErrorModal = true
      }
    }).catch((error: AxiosError) => {
      this.loanCreationInProgress = false
      this.errors.push(error.response!.data)
      this.showErrorModal = true
      return Promise.reject(error)
    }).then(() => {
      this.loanCreationInProgress = false
    })
  }
}
