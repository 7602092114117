export class Validators {
  public Required (errorMessage: string) : any {
    return (value: any) => (value != null && !!(value.toString())) || errorMessage
  }

  public Length (length: number, errorMessage: string) : any {
    return (value: any) => value.length === length || errorMessage
  }

  public MinLength (minLength: number, errorMessage: string) : any {
    return (value: any) => value.length >= minLength || errorMessage
  }

  public MaxLength (maxLength: number, errorMessage: string) : any {
    return (value: any) => value.length <= maxLength || errorMessage
  }

  public RegEx (pattern: RegExp, errorMessage: string) : any {
    return (value: any) => {
      return pattern.test(value) || errorMessage
    }
  }
}
