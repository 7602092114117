

















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AxiosResponse, AxiosError } from 'axios'
import { DdrModel } from '../models/ddr/ddr-model.type'
import { DdrUpdateModel } from '../models/ddr/ddr-update-model.type'
import { FormDataModel } from '../models/shared/form-data-model.type'
import { InstalmentIntervalEnum, InterestRateIntervalEnum } from '@/models/ddr/enums'
import { PaymentPlanModel } from '../models/ddr/payment-plan-model.type'
import { CalculationRequestModel } from '../models/ddr/calculation-request-model.type'
import { CalculationForUpdateRequestModel } from '../models/ddr/calculation-for-update-request-model.type'
import { CalculationViewModel } from '../models/ddr/calculation-view-model.type'
import { Validators } from '../components/validation/validators'
import { ResultResponseModel } from '../models/shared/result-response-model.type'
import { LabelValueModel } from '../models/shared/label-value-model.type'

@Component({})
export default class Ddr extends Vue {
  isBusy: boolean = false
  isDone: boolean = false
  validators = new Validators()
  customerName: string = ''
  c2msCustomerId: string = ''
  productId: string = ''
  editedItem: DdrModel = new DdrModel()
  originalDdrModel: DdrModel | null = null
  // todo: currently filtered to monthly only - probably needs configuration
  paymentFrequencies = Object.keys(InstalmentIntervalEnum).filter(key => !Number.isNaN(Number(key))).map(key => ({ id: +key, name: InstalmentIntervalEnum[+key] })).filter(key => key.id === InstalmentIntervalEnum.Monthly)
  isValid: boolean = false
  formData: any = {}
  paymentPlans: Array<PaymentPlanModel> | null = []
  selectedPaymentPlan: PaymentPlanModel | null = null
  sessionId: string = ''
  calculation: CalculationViewModel | null = null
  errors: Array<string> = []
  firstPaymentDateMenu: boolean = false
  firstPaymentDate: string = new Date().toISOString().substr(0, 10)
  depositDateMenu: boolean = false
  depositDate: string = new Date().toISOString().substr(0, 10)
  regularPaymentDays: Array<LabelValueModel> = []
  regularPaymentsMonthMenu: boolean = false
  regularPaymentsMonth: string = this.initRegularPaymentsMonth(new Date())
  returnUrl: string = ''
  isUpdate: boolean = false
  originalPaymentReference: string = ''
  inceptionDate: Date = new Date()

  get firstPaymentDateFormat () {
    return new Date(this.firstPaymentDate).toLocaleDateString('en-GB')
  }
  set firstPaymentDateFormat (value: string) {
    this.firstPaymentDate = new Date(value).toISOString().substr(0, 10)
  }

  get depositDateFormat () {
    return new Date(this.depositDate).toLocaleDateString('en-GB')
  }
  set depositDateFormat (value: string) {
    this.depositDate = new Date(value).toISOString().substr(0, 10)
  }

  get regularPaymentsMonthFormat () {
    return new Date(this.regularPaymentsMonth).toLocaleDateString('en-GB').substr(3, 7)
  }
  set regularPaymentsMonthFormat (value: string) {
    this.regularPaymentsMonth = new Date().toISOString().substr(0, 7)
  }

  private initRegularPaymentsMonth (inceptionDate : Date) : string {
    inceptionDate.setMonth(inceptionDate.getMonth() + 1)
    return inceptionDate.toISOString().substr(0, 7)
  }

  private created () {
    for (var i = 1; i <= 31; i++) {
      if (i === 31) {
        this.regularPaymentDays.push(new LabelValueModel('Last day of the month', i))
        continue
      }

      this.regularPaymentDays.push(new LabelValueModel(i.toString(), i))
    }

    // this.editedItem.policyNumber = new Date(date.setDate(date.getDate() + 10))
    this.editedItem.policyNumber = '-'
    this.editedItem.policyTotal = 0

    if (!this.$route.params['sessionId']) {
      return
    }

    this.sessionId = this.$route.params['sessionId']

    // validate session
    this.axios.post<FormDataModel>('api/forms/validatesession/' + this.sessionId, null).then((response: AxiosResponse<FormDataModel>) => {
      this.customerName = response.data.customerName
      this.returnUrl = response.data.returnUrl
      this.isUpdate = !!response.data.originalPaymentReference
      this.originalPaymentReference = response.data.originalPaymentReference
      this.inceptionDate = response.data.inceptionDate

      let inceptionDate = new Date(response.data.inceptionDate)

      // TWEAK FIRST PAYMENT DATE
      // grace period for L&Z is 6d - todo: parametrise
      let then = new Date()

      // add 6 working days to then
      for (let i = 0; i < 6; i++) {
        then = new Date(then.setDate(then.getDate() + 1))
        // saturday or sunday
        while (then.getDay() === 0 || then.getDay() === 6) {
          then = new Date(then.setDate(then.getDate() + 1))
        }
      }

      // increment inception date while it's lesser than grace period
      if (inceptionDate < then) {
        inceptionDate = new Date(then)
      }

      // check if inception date is on the weekend (SAT or SUN) and add days until MON
      while (inceptionDate.getDay() === 6 || inceptionDate.getDay() === 0) {
        inceptionDate = new Date(inceptionDate.setDate(inceptionDate.getDate() + 1))
      }
      // END TWEAKING FIRST PAYMENT DATE

      this.firstPaymentDate = inceptionDate.toISOString().substr(0, 10)
      // set regular payments day to original inceptionDate's day
      this.editedItem.regularPaymentsDate.day = new Date(response.data.inceptionDate).getDate()
      this.regularPaymentsMonth = this.initRegularPaymentsMonth(inceptionDate)
      this.c2msCustomerId = response.data.customer.id
      this.editedItem.policyNumber = response.data.policyId
      this.editedItem.policyTotal = response.data.amountTotal

      this.productId = response.data.productId

      this.errors = []

      // get existing customerId (if one exists)
      this.axios.get<ResultResponseModel<string>>('api/ddr/getcustomerid/' + this.sessionId + '/' + this.c2msCustomerId).then((response: AxiosResponse<ResultResponseModel<string>>) => {
        this.editedItem.customerId = this.handleResponse(response)!
      }).catch((error: AxiosError) => {
        this.errors.push('Could not validate if customer already exists in C2MS Direct Debit Module. Can not continue.')
        return Promise.reject(error)
      })
    }).catch((error: AxiosError) => {
      this.errors.push('Could not validate session. Session expired or does not exist. Can not continue.')
      return Promise.reject(error)
    })

    // fetch payment plans
    this.axios.get<ResultResponseModel<PaymentPlanModel[] | null>>('api/ddr/getpaymentplans/' + this.sessionId).then((response: AxiosResponse<ResultResponseModel<PaymentPlanModel[] | null>>) => {
      this.paymentPlans = this.handleResponse(response)

      if (this.paymentPlans && this.productId) {
        const defaultPaymentPlanForProduct = this.paymentPlans
          .find(pp => pp.productReferences && pp.productReferences.some(pppr => pppr.c2MSReference && pppr.c2MSReference.toLowerCase() === this.productId.toLowerCase()))

        if (defaultPaymentPlanForProduct) {
          this.editedItem.paymentPlanRevisionId = defaultPaymentPlanForProduct.revisionId
          this.selectedPaymentPlan = defaultPaymentPlanForProduct
        }
      }

      if (this.isUpdate) {
        // get existing ddr (if one exists)
        this.axios.get<ResultResponseModel<DdrModel>>('api/ddr/getexistingddr/' + this.sessionId + '/' + this.originalPaymentReference).then((response: AxiosResponse<ResultResponseModel<DdrModel>>) => {
          this.originalDdrModel = this.handleResponse(response)!
          this.editedItem.paymentPlanRevisionId = this.originalDdrModel.paymentPlanRevisionId
          this.paymentPlanChanged(this.editedItem.paymentPlanRevisionId)
        }).catch((error: AxiosError) => {
          this.errors.push('Could not fetch payment plan identifier of the original Direct Debit instruction. Can not continue with DDI update.')
          return Promise.reject(error)
        })
      }
    }).catch((error: AxiosError) => {
      this.errors.push('Could not obtain Payment plans. Can not continue.')
      return Promise.reject(error)
    })
  }

  handleResponse<T> (response: AxiosResponse<ResultResponseModel<T>>, clearErrors: boolean = true) : T | null {
    if (clearErrors) {
      this.errors = []
    }
    if (response.data.isValid) {
      return response.data.result
    } else {
      this.errors.push(...response.data.errors)
    }

    return response.data.result
  }

  formatDate (date: string) : string {
    // todo: should be configurable
    let d = new Date(date)

    return d.toLocaleDateString('en')
  }

  paymentPlanChanged (value: number) {
    let paymentPlan = this.paymentPlans!.find(p => p.revisionId === value)
    this.selectedPaymentPlan = paymentPlan!
    this.editedItem.depositAmount = 0
  }

  paymentPlanHasDeposit () : boolean {
    return !!this.selectedPaymentPlan && this.selectedPaymentPlan.hasDeposit
  }

  formatInterval (intervalId: number) : string {
    return InstalmentIntervalEnum[intervalId]
  }

  getCalculation () {
    this.isBusy = true

    let model = null

    model = new CalculationRequestModel()
    model.policyTotal = this.editedItem.policyTotal
    model.firstPaymentBy = '-'
    model.firstPaymentAmount = this.editedItem.depositAmount
    model.paymentPlanRevisionId = this.selectedPaymentPlan!.revisionId
    model.collectionFrequency = this.editedItem.instalmentIntervalId

    let endpoint = 'getcalculation'

    if (this.isUpdate) {
      endpoint = 'getcalculationforupdate'
      model = new CalculationForUpdateRequestModel()
      model.originalPaymentReference = this.originalPaymentReference
      model.newTotalAmount = this.editedItem.policyTotal
      model.dateEffective = new Date(this.inceptionDate)
    }

    // get calculation
    this.axios.post<ResultResponseModel<CalculationViewModel>>('api/ddr/' + endpoint + '/' + this.sessionId, model).then((response: AxiosResponse<ResultResponseModel<CalculationViewModel>>) => {
      this.calculation = this.handleResponse(response)
      this.isBusy = false
    }).catch((error: AxiosError) => {
      this.errors.push('Could not calculate. Please try again. Payment can not continue without calculation')

      this.isBusy = false

      return Promise.reject(error)
    })
  }

  save () {
    this.isBusy = true

    let regularPaymentsAsDate = new Date(this.regularPaymentsMonth)

    if (!this.isUpdate) {
      this.editedItem.regularPaymentsDate.month = regularPaymentsAsDate.getMonth() + 1
      this.editedItem.regularPaymentsDate.year = regularPaymentsAsDate.getFullYear()
      this.editedItem.firstPaymentDate = new Date(this.firstPaymentDate)

      if (!this.paymentPlanHasDeposit()) {
        this.editedItem.depositDate = null
        this.editedItem.depositAmount = 0
      } else {
        this.editedItem.depositDate = new Date(this.depositDate)
      }

      this.axios.post<ResultResponseModel<string>>('api/ddr/' + this.sessionId, this.editedItem)
        .then(this.saveSuccess)
        .catch((error: AxiosError) => {
          this.isBusy = false
          return Promise.reject(error)
        }).then(() => {
          this.isBusy = false
        })
    } else {
      let model = new DdrUpdateModel()
      model.dDIId = this.originalPaymentReference
      model.newTotalAmount = this.editedItem.policyTotal
      model.dateEffective = this.inceptionDate
      model.firstPaymentDate = new Date(this.firstPaymentDate)
      model.regularPaymentsDate.day = this.editedItem.regularPaymentsDate.day
      model.regularPaymentsDate.month = regularPaymentsAsDate.getMonth() + 1
      model.regularPaymentsDate.year = regularPaymentsAsDate.getFullYear()

      this.axios.put<ResultResponseModel<string>>('api/ddr/' + this.sessionId, model)
        .then(this.saveSuccess)
        .catch((error: AxiosError) => {
          this.isBusy = false
          return Promise.reject(error)
        }).then(() => {
          this.isBusy = false
        })
    }
  }

  saveSuccess (response: AxiosResponse<ResultResponseModel<string>>) {
    // set customer id always
    if (!this.editedItem.customerId) {
      this.editedItem.customerId = response.data.result!
    }
    this.errors = []

    if (response.data.isValid) {
      this.isDone = true

      if (parent) {
        parent.postMessage({ type: 'payment', success: true }, '*')
      }

      // redirect
      window.location.href = this.returnUrl
    } else {
      this.errors.push(...response.data.errors)
    }
  }
}
